<template>
  <b-container v-if="!isLoading" class="col">
    <b-card class="m-5">
      <b-card-body>
        <b-col>
          <b-row>
            <b-col cols="3">
              <img
                v-if="driverData.driver.driver_image === null"
                :src="require('@/assets/images/defaultavatar.png')"
                alt="Avatar"
                class="avatar"
              />
              <b-img
                v-else
                :src="baseURL + driverData.driver.driver_image"
                height="150"
                class="avatar rounded-circle w-auto"
              />
            </b-col>
            <b-col cols="7 mt-5">
              <h3>
                <b>{{ driverData["id"] }} -{{ driverData.name }}</b>
              </h3>
              <!-- <h6>RATINGS</h6>
            <b-form-rating id="rating-inline" variant="warning" inline value="4" style="margin-left:-20px !important"></b-form-rating> -->
              <h5><b>Franchise</b></h5>
              <h4
                v-if="driverData.branch_id === 5678"
                style="color: blueviolet"
              >
                <b>F Johor</b>
              </h4>
              <h4
                v-if="driverData.branch_id === 5679"
                style="color: blueviolet"
              >
                <b>F Taiping</b>
              </h4>
              <h4
                v-if="driverData.branch_id === 5680"
                style="color: blueviolet"
              >
                <b>F Seremban</b>
              </h4>
              <h4
                v-if="driverData.branch_id === 5681"
                style="color: blueviolet"
              >
                <b>F Melaka</b>
              </h4>
              <h4
                v-if="driverData.branch_id === 5683"
                style="color: blueviolet"
              >
                <b>F Penang</b>
              </h4>
            </b-col>
          </b-row>
        </b-col>
        <div>
          <hr />
        </div>
        <div class="m-5">
          <b-row class="mb-5">
            <b-col cols="2">
              <h5>
                <b> Date Created </b>
              </h5>
            </b-col>
            <b-col cols="7">
              <h5>
                {{ driverData.driver.registration_date }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col cols="2">
              <h5>
                <b> Address </b>
              </h5>
            </b-col>
            <b-col cols="7">
              <h5>
                {{ driverData.driver.unit_no }}
                {{ driverData.driver.address_1 }}
                {{ driverData.driver.address_2 }}, {{ driverData.driver.area }},
                {{ driverData.driver.state }}, {{ driverData.driver.country }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col cols="2">
              <h5>
                <b> Phone </b>
              </h5>
            </b-col>
            <b-col cols="7">
              <h5>{{ driverData.phone }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              <h5>
                <b> Email Address </b>
              </h5>
            </b-col>
            <b-col cols="7">
              <h5>{{ driverData.email }}</h5>
            </b-col>
          </b-row>
        </div>
        <div class="d-flex justify-content-center align-items-center my-2">
          <b-button
            variant="gradient-primary"
            class="w-40 box-shadow-1"
            to="/phone-book"
          >
            Back to Drivers
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import axios from "@axios";

export default {
  data() {
    return {
      isLoading: true,
      baseURL: `${this.$s3URL}/minidc/driver_photo/`,
      driverData: {},
    };
  },
  created() {
    this.getDetails(this.$route.params.id);
  },
  methods: {
    async getDetails(id) {
      await this.$http.get(`driver/${id}`).then((response) => {
        if (response.data.status) {
          this.driverData = response.data.data;
          this.isLoading = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar {
  vertical-align: middle;
  max-width: 220px;
  height: auto;
  border-radius: 50%;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "src/assets/scss/style.scss";
</style>
